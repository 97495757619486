<template>
    <div class="row">
        <div class="col-lg-12">
            <div class="row group-container pt-4 group-main">
                <h5 class="title-group">ผลประโยชน์</h5>
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-3">
                            <button
                                :disabled="get(data, 'step') == 0"
                                @click="handle.step('back')"
                                class="btn btn-light float-left"
                            >
                                เดือนก่อน
                            </button>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <select v-model="data.insure" @change="handle.changed" class="form-control">
                                    <option :value="20000">ส่งเบี้ย 20,000/เดือน</option>
                                    <option :value="30000">ส่งเบี้ย 30,000/เดือน</option>
                                    <option :value="40000">ส่งเบี้ย 40,000/เดือน</option>
                                    <option :value="50000">ส่งเบี้ย 50,000/เดือน</option>
                                    <option :value="60000">ส่งเบี้ย 60,000/เดือน</option>
                                    <option :value="70000">ส่งเบี้ย 70,000/เดือน</option>
                                    <option :value="80000">ส่งเบี้ย 80,000/เดือน</option>
                                    <option :value="90000">ส่งเบี้ย 90,000/เดือน</option>
                                    <option :value="100000">ส่งเบี้ย 100,000/เดือน</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <select v-model="data.year" @change="handle.changed" class="form-control">
                                    <option :value="1">คอมมิชชั่น ปีที่ 1</option>
                                    <option :value="2">คอมมิชชั่น ปีที่ 2</option>
                                    <option :value="3">คอมมิชชั่น ปีที่ 3</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <button
                                :disabled="get(data, 'step') == 11"
                                @click="handle.step('next')"
                                class="btn btn-light float-right"
                            >
                                เดือนถัดไป
                            </button>
                        </div>
                        <div class="col-md-12"><hr /></div>
                        <div class="col-md-12">
                            <div class="table-responsive">
                                <table class="table-commitssion table table-borderless">
                                    <tbody>
                                        <tr>
                                            <td v-if="data.row" width="10">0</td>
                                            <td width="70"></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td v-if="find(get(step, data.step), (f) => f == 1)" class="M12 head">
                                                ธ.ค.
                                            </td>
                                        </tr>
                                        <tr>
                                            <td v-if="data.row">1</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td v-if="find(get(step, data.step), (f) => f == 2)" class="M11 head">
                                                พ.ย.
                                            </td>
                                            <td
                                                :class="{ M12: find(get(step, data.step), (f) => f == 1) }"
                                                class="first last"
                                            >
                                                {{
                                                    find(get(step, data.step), (f) => f == 1) ? get(data, 'insure') : ''
                                                }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td v-if="data.row">2</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td v-if="find(get(step, data.step), (f) => f == 3)" class="M10 head">
                                                ตุ.ค.
                                            </td>
                                            <td
                                                :class="{ M11: find(get(step, data.step), (f) => f == 2) }"
                                                class="first"
                                            >
                                                {{
                                                    find(get(step, data.step), (f) => f == 2) ? get(data, 'insure') : ''
                                                }}
                                            </td>
                                            <td
                                                :class="{ M11: find(get(step, data.step), (f) => f == 2) }"
                                                class="last"
                                            >
                                                {{
                                                    find(get(step, data.step), (f) => f == 2) ? get(data, 'insure') : ''
                                                }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td v-if="data.row">3</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td v-if="find(get(step, data.step), (f) => f == 4)" class="M9 head">
                                                ก.ย.
                                            </td>
                                            <td
                                                :class="{ M10: find(get(step, data.step), (f) => f == 3) }"
                                                class="first"
                                            >
                                                {{
                                                    find(get(step, data.step), (f) => f == 3) ? get(data, 'insure') : ''
                                                }}
                                            </td>
                                            <td :class="{ M10: find(get(step, data.step), (f) => f == 3) }">
                                                {{
                                                    find(get(step, data.step), (f) => f == 3) ? get(data, 'insure') : ''
                                                }}
                                            </td>
                                            <td
                                                :class="{ M10: find(get(step, data.step), (f) => f == 3) }"
                                                class="last"
                                            >
                                                {{
                                                    find(get(step, data.step), (f) => f == 3) ? get(data, 'insure') : ''
                                                }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td v-if="data.row">4</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td v-if="find(get(step, data.step), (f) => f == 5)" class="M8 head">
                                                สิ.ค.
                                            </td>
                                            <td
                                                :class="{ M9: find(get(step, data.step), (f) => f == 4) }"
                                                class="first"
                                            >
                                                {{
                                                    find(get(step, data.step), (f) => f == 4) ? get(data, 'insure') : ''
                                                }}
                                            </td>
                                            <td :class="{ M9: find(get(step, data.step), (f) => f == 4) }">
                                                {{
                                                    find(get(step, data.step), (f) => f == 4) ? get(data, 'insure') : ''
                                                }}
                                            </td>
                                            <td :class="{ M9: find(get(step, data.step), (f) => f == 4) }">
                                                {{
                                                    find(get(step, data.step), (f) => f == 4) ? get(data, 'insure') : ''
                                                }}
                                            </td>
                                            <td :class="{ M9: find(get(step, data.step), (f) => f == 4) }" class="last">
                                                {{
                                                    find(get(step, data.step), (f) => f == 4) ? get(data, 'insure') : ''
                                                }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td v-if="data.row">5</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td v-if="find(get(step, data.step), (f) => f == 6)" class="M7 head">
                                                ก.ค.
                                            </td>
                                            <td
                                                :class="{ M8: find(get(step, data.step), (f) => f == 5) }"
                                                class="first"
                                            >
                                                {{
                                                    find(get(step, data.step), (f) => f == 5) ? get(data, 'insure') : ''
                                                }}
                                            </td>
                                            <td :class="{ M8: find(get(step, data.step), (f) => f == 5) }">
                                                {{
                                                    find(get(step, data.step), (f) => f == 5) ? get(data, 'insure') : ''
                                                }}
                                            </td>
                                            <td :class="{ M8: find(get(step, data.step), (f) => f == 5) }">
                                                {{
                                                    find(get(step, data.step), (f) => f == 5) ? get(data, 'insure') : ''
                                                }}
                                            </td>
                                            <td :class="{ M8: find(get(step, data.step), (f) => f == 5) }">
                                                {{
                                                    find(get(step, data.step), (f) => f == 5) ? get(data, 'insure') : ''
                                                }}
                                            </td>
                                            <td :class="{ M8: find(get(step, data.step), (f) => f == 5) }" class="last">
                                                {{
                                                    find(get(step, data.step), (f) => f == 5) ? get(data, 'insure') : ''
                                                }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td v-if="data.row">6</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td v-if="find(get(step, data.step), (f) => f == 7)" class="M6 head">
                                                มิ.ย.
                                            </td>
                                            <td
                                                :class="{ M7: find(get(step, data.step), (f) => f == 6) }"
                                                class="first"
                                            >
                                                {{
                                                    find(get(step, data.step), (f) => f == 6) ? get(data, 'insure') : ''
                                                }}
                                            </td>
                                            <td :class="{ M7: find(get(step, data.step), (f) => f == 6) }">
                                                {{
                                                    find(get(step, data.step), (f) => f == 6) ? get(data, 'insure') : ''
                                                }}
                                            </td>
                                            <td :class="{ M7: find(get(step, data.step), (f) => f == 6) }">
                                                {{
                                                    find(get(step, data.step), (f) => f == 6) ? get(data, 'insure') : ''
                                                }}
                                            </td>
                                            <td :class="{ M7: find(get(step, data.step), (f) => f == 6) }">
                                                {{
                                                    find(get(step, data.step), (f) => f == 6) ? get(data, 'insure') : ''
                                                }}
                                            </td>
                                            <td :class="{ M7: find(get(step, data.step), (f) => f == 6) }">
                                                {{
                                                    find(get(step, data.step), (f) => f == 6) ? get(data, 'insure') : ''
                                                }}
                                            </td>
                                            <td :class="{ M7: find(get(step, data.step), (f) => f == 6) }" class="last">
                                                {{
                                                    find(get(step, data.step), (f) => f == 6) ? get(data, 'insure') : ''
                                                }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td v-if="data.row">7</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td v-if="find(get(step, data.step), (f) => f == 8)" class="M5 head">
                                                พ.ค.
                                            </td>
                                            <td
                                                :class="{ M6: find(get(step, data.step), (f) => f == 7) }"
                                                class="first"
                                            >
                                                {{
                                                    find(get(step, data.step), (f) => f == 7) ? get(data, 'insure') : ''
                                                }}
                                            </td>
                                            <td :class="{ M6: find(get(step, data.step), (f) => f == 7) }">
                                                {{
                                                    find(get(step, data.step), (f) => f == 7) ? get(data, 'insure') : ''
                                                }}
                                            </td>
                                            <td :class="{ M6: find(get(step, data.step), (f) => f == 7) }">
                                                {{
                                                    find(get(step, data.step), (f) => f == 7) ? get(data, 'insure') : ''
                                                }}
                                            </td>
                                            <td :class="{ M6: find(get(step, data.step), (f) => f == 7) }">
                                                {{
                                                    find(get(step, data.step), (f) => f == 7) ? get(data, 'insure') : ''
                                                }}
                                            </td>
                                            <td :class="{ M6: find(get(step, data.step), (f) => f == 7) }">
                                                {{
                                                    find(get(step, data.step), (f) => f == 7) ? get(data, 'insure') : ''
                                                }}
                                            </td>
                                            <td :class="{ M6: find(get(step, data.step), (f) => f == 7) }">
                                                {{
                                                    find(get(step, data.step), (f) => f == 7) ? get(data, 'insure') : ''
                                                }}
                                            </td>
                                            <td :class="{ M6: find(get(step, data.step), (f) => f == 7) }" class="last">
                                                {{
                                                    find(get(step, data.step), (f) => f == 7) ? get(data, 'insure') : ''
                                                }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td v-if="data.row">8</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td v-if="find(get(step, data.step), (f) => f == 9)" class="M4 head">
                                                เม.ย.
                                            </td>
                                            <td
                                                :class="{ M5: find(get(step, data.step), (f) => f == 8) }"
                                                class="first"
                                            >
                                                {{
                                                    find(get(step, data.step), (f) => f == 8) ? get(data, 'insure') : ''
                                                }}
                                            </td>
                                            <td :class="{ M5: find(get(step, data.step), (f) => f == 8) }">
                                                {{
                                                    find(get(step, data.step), (f) => f == 8) ? get(data, 'insure') : ''
                                                }}
                                            </td>
                                            <td :class="{ M5: find(get(step, data.step), (f) => f == 8) }">
                                                {{
                                                    find(get(step, data.step), (f) => f == 8) ? get(data, 'insure') : ''
                                                }}
                                            </td>
                                            <td :class="{ M5: find(get(step, data.step), (f) => f == 8) }">
                                                {{
                                                    find(get(step, data.step), (f) => f == 8) ? get(data, 'insure') : ''
                                                }}
                                            </td>
                                            <td :class="{ M5: find(get(step, data.step), (f) => f == 8) }">
                                                {{
                                                    find(get(step, data.step), (f) => f == 8) ? get(data, 'insure') : ''
                                                }}
                                            </td>
                                            <td :class="{ M5: find(get(step, data.step), (f) => f == 8) }">
                                                {{
                                                    find(get(step, data.step), (f) => f == 8) ? get(data, 'insure') : ''
                                                }}
                                            </td>
                                            <td :class="{ M5: find(get(step, data.step), (f) => f == 8) }">
                                                {{
                                                    find(get(step, data.step), (f) => f == 8) ? get(data, 'insure') : ''
                                                }}
                                            </td>
                                            <td :class="{ M5: find(get(step, data.step), (f) => f == 8) }" class="last">
                                                {{
                                                    find(get(step, data.step), (f) => f == 8) ? get(data, 'insure') : ''
                                                }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td v-if="data.row">9</td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td v-if="find(get(step, data.step), (f) => f == 10)" class="M3 head">
                                                มี.ค.
                                            </td>
                                            <td
                                                :class="{ M4: find(get(step, data.step), (f) => f == 9) }"
                                                class="first"
                                            >
                                                {{
                                                    find(get(step, data.step), (f) => f == 9) ? get(data, 'insure') : ''
                                                }}
                                            </td>
                                            <td :class="{ M4: find(get(step, data.step), (f) => f == 9) }">
                                                {{
                                                    find(get(step, data.step), (f) => f == 9) ? get(data, 'insure') : ''
                                                }}
                                            </td>
                                            <td :class="{ M4: find(get(step, data.step), (f) => f == 9) }">
                                                {{
                                                    find(get(step, data.step), (f) => f == 9) ? get(data, 'insure') : ''
                                                }}
                                            </td>
                                            <td :class="{ M4: find(get(step, data.step), (f) => f == 9) }">
                                                {{
                                                    find(get(step, data.step), (f) => f == 9) ? get(data, 'insure') : ''
                                                }}
                                            </td>
                                            <td :class="{ M4: find(get(step, data.step), (f) => f == 9) }">
                                                {{
                                                    find(get(step, data.step), (f) => f == 9) ? get(data, 'insure') : ''
                                                }}
                                            </td>
                                            <td :class="{ M4: find(get(step, data.step), (f) => f == 9) }">
                                                {{
                                                    find(get(step, data.step), (f) => f == 9) ? get(data, 'insure') : ''
                                                }}
                                            </td>
                                            <td :class="{ M4: find(get(step, data.step), (f) => f == 9) }">
                                                {{
                                                    find(get(step, data.step), (f) => f == 9) ? get(data, 'insure') : ''
                                                }}
                                            </td>
                                            <td :class="{ M4: find(get(step, data.step), (f) => f == 9) }">
                                                {{
                                                    find(get(step, data.step), (f) => f == 9) ? get(data, 'insure') : ''
                                                }}
                                            </td>
                                            <td :class="{ M4: find(get(step, data.step), (f) => f == 9) }" class="last">
                                                {{
                                                    find(get(step, data.step), (f) => f == 9) ? get(data, 'insure') : ''
                                                }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td v-if="data.row">10</td>
                                            <td></td>
                                            <td></td>
                                            <td v-if="find(get(step, data.step), (f) => f == 11)" class="M2 head">
                                                ก.พ.
                                            </td>
                                            <td
                                                :class="{ M3: find(get(step, data.step), (f) => f == 10) }"
                                                class="first"
                                            >
                                                {{
                                                    find(get(step, data.step), (f) => f == 10)
                                                        ? get(data, 'insure')
                                                        : ''
                                                }}
                                            </td>
                                            <td :class="{ M3: find(get(step, data.step), (f) => f == 10) }">
                                                {{
                                                    find(get(step, data.step), (f) => f == 10)
                                                        ? get(data, 'insure')
                                                        : ''
                                                }}
                                            </td>
                                            <td :class="{ M3: find(get(step, data.step), (f) => f == 10) }">
                                                {{
                                                    find(get(step, data.step), (f) => f == 10)
                                                        ? get(data, 'insure')
                                                        : ''
                                                }}
                                            </td>
                                            <td :class="{ M3: find(get(step, data.step), (f) => f == 10) }">
                                                {{
                                                    find(get(step, data.step), (f) => f == 10)
                                                        ? get(data, 'insure')
                                                        : ''
                                                }}
                                            </td>
                                            <td :class="{ M3: find(get(step, data.step), (f) => f == 10) }">
                                                {{
                                                    find(get(step, data.step), (f) => f == 10)
                                                        ? get(data, 'insure')
                                                        : ''
                                                }}
                                            </td>
                                            <td :class="{ M3: find(get(step, data.step), (f) => f == 10) }">
                                                {{
                                                    find(get(step, data.step), (f) => f == 10)
                                                        ? get(data, 'insure')
                                                        : ''
                                                }}
                                            </td>
                                            <td :class="{ M3: find(get(step, data.step), (f) => f == 10) }">
                                                {{
                                                    find(get(step, data.step), (f) => f == 10)
                                                        ? get(data, 'insure')
                                                        : ''
                                                }}
                                            </td>
                                            <td :class="{ M3: find(get(step, data.step), (f) => f == 10) }">
                                                {{
                                                    find(get(step, data.step), (f) => f == 10)
                                                        ? get(data, 'insure')
                                                        : ''
                                                }}
                                            </td>
                                            <td :class="{ M3: find(get(step, data.step), (f) => f == 10) }">
                                                {{
                                                    find(get(step, data.step), (f) => f == 10)
                                                        ? get(data, 'insure')
                                                        : ''
                                                }}
                                            </td>
                                            <td
                                                :class="{ M3: find(get(step, data.step), (f) => f == 10) }"
                                                class="last"
                                            >
                                                {{
                                                    find(get(step, data.step), (f) => f == 10)
                                                        ? get(data, 'insure')
                                                        : ''
                                                }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td v-if="data.row">11</td>
                                            <td></td>
                                            <td v-if="find(get(step, data.step), (f) => f == 12)" class="M1 head">
                                                ม.ค.
                                            </td>
                                            <td
                                                :class="{ M2: find(get(step, data.step), (f) => f == 11) }"
                                                class="first"
                                            >
                                                {{
                                                    find(get(step, data.step), (f) => f == 11)
                                                        ? get(data, 'insure')
                                                        : ''
                                                }}
                                            </td>
                                            <td :class="{ M2: find(get(step, data.step), (f) => f == 11) }">
                                                {{
                                                    find(get(step, data.step), (f) => f == 11)
                                                        ? get(data, 'insure')
                                                        : ''
                                                }}
                                            </td>
                                            <td :class="{ M2: find(get(step, data.step), (f) => f == 11) }">
                                                {{
                                                    find(get(step, data.step), (f) => f == 11)
                                                        ? get(data, 'insure')
                                                        : ''
                                                }}
                                            </td>
                                            <td :class="{ M2: find(get(step, data.step), (f) => f == 11) }">
                                                {{
                                                    find(get(step, data.step), (f) => f == 11)
                                                        ? get(data, 'insure')
                                                        : ''
                                                }}
                                            </td>
                                            <td :class="{ M2: find(get(step, data.step), (f) => f == 11) }">
                                                {{
                                                    find(get(step, data.step), (f) => f == 11)
                                                        ? get(data, 'insure')
                                                        : ''
                                                }}
                                            </td>
                                            <td :class="{ M2: find(get(step, data.step), (f) => f == 11) }">
                                                {{
                                                    find(get(step, data.step), (f) => f == 11)
                                                        ? get(data, 'insure')
                                                        : ''
                                                }}
                                            </td>
                                            <td :class="{ M2: find(get(step, data.step), (f) => f == 11) }">
                                                {{
                                                    find(get(step, data.step), (f) => f == 11)
                                                        ? get(data, 'insure')
                                                        : ''
                                                }}
                                            </td>
                                            <td :class="{ M2: find(get(step, data.step), (f) => f == 11) }">
                                                {{
                                                    find(get(step, data.step), (f) => f == 11)
                                                        ? get(data, 'insure')
                                                        : ''
                                                }}
                                            </td>
                                            <td :class="{ M2: find(get(step, data.step), (f) => f == 11) }">
                                                {{
                                                    find(get(step, data.step), (f) => f == 11)
                                                        ? get(data, 'insure')
                                                        : ''
                                                }}
                                            </td>
                                            <td :class="{ M2: find(get(step, data.step), (f) => f == 11) }">
                                                {{
                                                    find(get(step, data.step), (f) => f == 11)
                                                        ? get(data, 'insure')
                                                        : ''
                                                }}
                                            </td>
                                            <td
                                                :class="{ M2: find(get(step, data.step), (f) => f == 11) }"
                                                class="last"
                                            >
                                                {{
                                                    find(get(step, data.step), (f) => f == 11)
                                                        ? get(data, 'insure')
                                                        : ''
                                                }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td v-if="data.row">12</td>
                                            <td></td>
                                            <td
                                                :class="{ M1: find(get(step, data.step), (f) => f == 12) }"
                                                class="first"
                                            >
                                                {{
                                                    find(get(step, data.step), (f) => f == 12)
                                                        ? get(data, 'insure')
                                                        : ''
                                                }}
                                            </td>
                                            <td :class="{ M1: find(get(step, data.step), (f) => f == 12) }">
                                                {{
                                                    find(get(step, data.step), (f) => f == 12)
                                                        ? get(data, 'insure')
                                                        : ''
                                                }}
                                            </td>
                                            <td :class="{ M1: find(get(step, data.step), (f) => f == 12) }">
                                                {{
                                                    find(get(step, data.step), (f) => f == 12)
                                                        ? get(data, 'insure')
                                                        : ''
                                                }}
                                            </td>
                                            <td :class="{ M1: find(get(step, data.step), (f) => f == 12) }">
                                                {{
                                                    find(get(step, data.step), (f) => f == 12)
                                                        ? get(data, 'insure')
                                                        : ''
                                                }}
                                            </td>
                                            <td :class="{ M1: find(get(step, data.step), (f) => f == 12) }">
                                                {{
                                                    find(get(step, data.step), (f) => f == 12)
                                                        ? get(data, 'insure')
                                                        : ''
                                                }}
                                            </td>
                                            <td :class="{ M1: find(get(step, data.step), (f) => f == 12) }">
                                                {{
                                                    find(get(step, data.step), (f) => f == 12)
                                                        ? get(data, 'insure')
                                                        : ''
                                                }}
                                            </td>
                                            <td :class="{ M1: find(get(step, data.step), (f) => f == 12) }">
                                                {{
                                                    find(get(step, data.step), (f) => f == 12)
                                                        ? get(data, 'insure')
                                                        : ''
                                                }}
                                            </td>
                                            <td :class="{ M1: find(get(step, data.step), (f) => f == 12) }">
                                                {{
                                                    find(get(step, data.step), (f) => f == 12)
                                                        ? get(data, 'insure')
                                                        : ''
                                                }}
                                            </td>
                                            <td :class="{ M1: find(get(step, data.step), (f) => f == 12) }">
                                                {{
                                                    find(get(step, data.step), (f) => f == 12)
                                                        ? get(data, 'insure')
                                                        : ''
                                                }}
                                            </td>
                                            <td :class="{ M1: find(get(step, data.step), (f) => f == 12) }">
                                                {{
                                                    find(get(step, data.step), (f) => f == 12)
                                                        ? get(data, 'insure')
                                                        : ''
                                                }}
                                            </td>
                                            <td :class="{ M1: find(get(step, data.step), (f) => f == 12) }">
                                                {{
                                                    find(get(step, data.step), (f) => f == 12)
                                                        ? get(data, 'insure')
                                                        : ''
                                                }}
                                            </td>
                                            <td
                                                :class="{ M1: find(get(step, data.step), (f) => f == 12) }"
                                                class="last"
                                            >
                                                {{
                                                    find(get(step, data.step), (f) => f == 12)
                                                        ? get(data, 'insure')
                                                        : ''
                                                }}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td v-if="data.row"></td>
                                            <td></td>
                                            <td class="text-center"><i class="fas fa-arrow-down"></i></td>
                                            <td class="text-center"><i class="fas fa-arrow-down"></i></td>
                                            <td class="text-center"><i class="fas fa-arrow-down"></i></td>
                                            <td class="text-center"><i class="fas fa-arrow-down"></i></td>
                                            <td class="text-center"><i class="fas fa-arrow-down"></i></td>
                                            <td class="text-center"><i class="fas fa-arrow-down"></i></td>
                                            <td class="text-center"><i class="fas fa-arrow-down"></i></td>
                                            <td class="text-center"><i class="fas fa-arrow-down"></i></td>
                                            <td class="text-center"><i class="fas fa-arrow-down"></i></td>
                                            <td class="text-center"><i class="fas fa-arrow-down"></i></td>
                                            <td class="text-center"><i class="fas fa-arrow-down"></i></td>
                                            <td class="text-center"><i class="fas fa-arrow-down"></i></td>
                                        </tr>

                                        <tr>
                                            <td v-if="data.row">13</td>
                                            <td class="text-right">FYC</td>
                                            <td class="head" :class="{ M1: get(data, 'step') == 0 }">
                                                {{ nf(handle.fyc(1)) }}
                                            </td>
                                            <td class="head" :class="{ M2: get(data, 'step') == 1 }">
                                                {{ nf(handle.fyc(2)) }}
                                            </td>
                                            <td class="head" :class="{ M3: get(data, 'step') == 2 }">
                                                {{ nf(handle.fyc(3)) }}
                                            </td>
                                            <td class="head" :class="{ M4: get(data, 'step') == 3 }">
                                                {{ nf(handle.fyc(4)) }}
                                            </td>
                                            <td class="head" :class="{ M5: get(data, 'step') == 4 }">
                                                {{ nf(handle.fyc(5)) }}
                                            </td>
                                            <td class="head" :class="{ M6: get(data, 'step') == 5 }">
                                                {{ nf(handle.fyc(6)) }}
                                            </td>
                                            <td class="head" :class="{ M7: get(data, 'step') == 6 }">
                                                {{ nf(handle.fyc(7)) }}
                                            </td>
                                            <td class="head" :class="{ M8: get(data, 'step') == 7 }">
                                                {{ nf(handle.fyc(8)) }}
                                            </td>
                                            <td class="head" :class="{ M9: get(data, 'step') == 8 }">
                                                {{ nf(handle.fyc(9)) }}
                                            </td>
                                            <td class="head" :class="{ M10: get(data, 'step') == 9 }">
                                                {{ nf(handle.fyc(10)) }}
                                            </td>
                                            <td class="head" :class="{ M11: get(data, 'step') == 10 }">
                                                {{ nf(handle.fyc(11)) }}
                                            </td>
                                            <td class="head" :class="{ M12: get(data, 'step') == 11 }">
                                                {{ nf(handle.fyc(12)) }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td v-if="data.row">14</td>
                                            <td class="text-right">RYC1</td>
                                            <td :class="{ M1: get(data, 'step') == 0 }">
                                                {{ nf(handle.ryc(1, 1)) }}
                                            </td>
                                            <td :class="{ M2: get(data, 'step') == 1 }">
                                                {{ nf(handle.ryc(1, 2)) }}
                                            </td>
                                            <td :class="{ M3: get(data, 'step') == 2 }">
                                                {{ nf(handle.ryc(1, 3)) }}
                                            </td>
                                            <td :class="{ M4: get(data, 'step') == 3 }">
                                                {{ nf(handle.ryc(1, 4)) }}
                                            </td>
                                            <td :class="{ M5: get(data, 'step') == 4 }">
                                                {{ nf(handle.ryc(1, 5)) }}
                                            </td>
                                            <td :class="{ M6: get(data, 'step') == 5 }">
                                                {{ nf(handle.ryc(1, 6)) }}
                                            </td>
                                            <td :class="{ M7: get(data, 'step') == 6 }">
                                                {{ nf(handle.ryc(1, 7)) }}
                                            </td>
                                            <td :class="{ M8: get(data, 'step') == 7 }">
                                                {{ nf(handle.ryc(1, 8)) }}
                                            </td>
                                            <td :class="{ M9: get(data, 'step') == 8 }">
                                                {{ nf(handle.ryc(1, 9)) }}
                                            </td>
                                            <td :class="{ M10: get(data, 'step') == 9 }">
                                                {{ nf(handle.ryc(1, 10)) }}
                                            </td>
                                            <td :class="{ M11: get(data, 'step') == 10 }">
                                                {{ nf(handle.ryc(1, 11)) }}
                                            </td>
                                            <td :class="{ M12: get(data, 'step') == 11 }">
                                                {{ nf(handle.ryc(1, 12)) }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td v-if="data.row">15</td>
                                            <td class="text-right">RYC2</td>
                                            <td :class="{ M1: get(data, 'step') == 0 }">
                                                {{ nf(handle.ryc(2, 1)) }}
                                            </td>
                                            <td :class="{ M2: get(data, 'step') == 1 }">
                                                {{ nf(handle.ryc(2, 2)) }}
                                            </td>
                                            <td :class="{ M3: get(data, 'step') == 2 }">
                                                {{ nf(handle.ryc(2, 3)) }}
                                            </td>
                                            <td :class="{ M4: get(data, 'step') == 3 }">
                                                {{ nf(handle.ryc(2, 4)) }}
                                            </td>
                                            <td :class="{ M5: get(data, 'step') == 4 }">
                                                {{ nf(handle.ryc(2, 5)) }}
                                            </td>
                                            <td :class="{ M6: get(data, 'step') == 5 }">
                                                {{ nf(handle.ryc(2, 6)) }}
                                            </td>
                                            <td :class="{ M7: get(data, 'step') == 6 }">
                                                {{ nf(handle.ryc(2, 7)) }}
                                            </td>
                                            <td :class="{ M8: get(data, 'step') == 7 }">
                                                {{ nf(handle.ryc(2, 8)) }}
                                            </td>
                                            <td :class="{ M9: get(data, 'step') == 8 }">
                                                {{ nf(handle.ryc(2, 9)) }}
                                            </td>
                                            <td :class="{ M10: get(data, 'step') == 9 }">
                                                {{ nf(handle.ryc(2, 10)) }}
                                            </td>
                                            <td :class="{ M11: get(data, 'step') == 10 }">
                                                {{ nf(handle.ryc(2, 11)) }}
                                            </td>
                                            <td :class="{ M12: get(data, 'step') == 11 }">
                                                {{ nf(handle.ryc(2, 12)) }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td v-if="data.row">16</td>
                                            <td class="text-right">CB</td>
                                            <td :class="{ M1: get(data, 'step') == 0 }">
                                                {{ get(data, 'year') == 3 ? nf(get(data, 'career') / 12) : '0' }}
                                            </td>
                                            <td :class="{ M2: get(data, 'step') == 1 }">
                                                {{ get(data, 'year') == 3 ? nf(get(data, 'career') / 12) : '0' }}
                                            </td>
                                            <td :class="{ M3: get(data, 'step') == 2 }">
                                                {{ get(data, 'year') == 3 ? nf(get(data, 'career') / 12) : '0' }}
                                            </td>
                                            <td :class="{ M4: get(data, 'step') == 3 }">
                                                {{ get(data, 'year') == 3 ? nf(get(data, 'career') / 12) : '0' }}
                                            </td>
                                            <td :class="{ M5: get(data, 'step') == 4 }">
                                                {{ get(data, 'year') == 3 ? nf(get(data, 'career') / 12) : '0' }}
                                            </td>
                                            <td :class="{ M6: get(data, 'step') == 5 }">
                                                {{ get(data, 'year') == 3 ? nf(get(data, 'career') / 12) : '0' }}
                                            </td>
                                            <td :class="{ M7: get(data, 'step') == 6 }">
                                                {{ get(data, 'year') == 3 ? nf(get(data, 'career') / 12) : '0' }}
                                            </td>
                                            <td :class="{ M8: get(data, 'step') == 7 }">
                                                {{ get(data, 'year') == 3 ? nf(get(data, 'career') / 12) : '0' }}
                                            </td>
                                            <td :class="{ M9: get(data, 'step') == 8 }">
                                                {{ get(data, 'year') == 3 ? nf(get(data, 'career') / 12) : '0' }}
                                            </td>
                                            <td :class="{ M10: get(data, 'step') == 9 }">
                                                {{ get(data, 'year') == 3 ? nf(get(data, 'career') / 12) : '0' }}
                                            </td>
                                            <td :class="{ M11: get(data, 'step') == 10 }">
                                                {{ get(data, 'year') == 3 ? nf(get(data, 'career') / 12) : '0' }}
                                            </td>
                                            <td :class="{ M12: get(data, 'step') == 11 }">
                                                {{ get(data, 'year') == 3 ? nf(get(data, 'career') / 12) : '0' }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td v-if="data.row">17</td>
                                            <td class="text-right color-m1">Total</td>
                                            <td class="total bottom" :class="{ M1: get(data, 'step') == 0 }">
                                                {{ nf(handle.total(1)) }}
                                            </td>
                                            <td class="total bottom" :class="{ M2: get(data, 'step') == 1 }">
                                                {{ nf(handle.total(2)) }}
                                            </td>
                                            <td class="total bottom" :class="{ M3: get(data, 'step') == 2 }">
                                                {{ nf(handle.total(3)) }}
                                            </td>
                                            <td class="total bottom" :class="{ M4: get(data, 'step') == 3 }">
                                                {{ nf(handle.total(4)) }}
                                            </td>
                                            <td class="total bottom" :class="{ M5: get(data, 'step') == 4 }">
                                                {{ nf(handle.total(5)) }}
                                            </td>
                                            <td class="total bottom" :class="{ M6: get(data, 'step') == 5 }">
                                                {{ nf(handle.total(6)) }}
                                            </td>
                                            <td class="total bottom" :class="{ M7: get(data, 'step') == 6 }">
                                                {{ nf(handle.total(7)) }}
                                            </td>
                                            <td class="total bottom" :class="{ M8: get(data, 'step') == 7 }">
                                                {{ nf(handle.total(8)) }}
                                            </td>
                                            <td class="total bottom" :class="{ M9: get(data, 'step') == 8 }">
                                                {{ nf(handle.total(9)) }}
                                            </td>
                                            <td class="total bottom" :class="{ M10: get(data, 'step') == 9 }">
                                                {{ nf(handle.total(10)) }}
                                            </td>
                                            <td class="total bottom" :class="{ M11: get(data, 'step') == 10 }">
                                                {{ nf(handle.total(11)) }}
                                            </td>
                                            <td class="total bottom" :class="{ M12: get(data, 'step') == 11 }">
                                                {{ nf(handle.total(12)) }}
                                            </td>
                                        </tr>
                                        <tr v-if="data.row">
                                            <td></td>
                                            <td>0</td>
                                            <td>1</td>
                                            <td>2</td>
                                            <td>3</td>
                                            <td>4</td>
                                            <td>5</td>
                                            <td>6</td>
                                            <td>7</td>
                                            <td>8</td>
                                            <td>9</td>
                                            <td>10</td>
                                            <td>11</td>
                                            <td>12</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, reactive } from '@vue/composition-api';

//* plugins
import { state, nf } from '@/assets/js/tooltify';
import { get, set, find } from 'lodash';

export default {
    name: 'commission',
    components: {},
    setup() {
        const store = reactive({
            commission: state.getters('COMMISSION_GETTERS'),
        });

        const data = reactive({
            row: false,
            year: 1,
            step: 0,
            insure: 30000,
            career: 0,
        });

        const step = get(store, 'commission.step');
        const total = get(store, 'commission.total');

        onMounted(() => {
            handle.changed();
        });

        const handle = {
            step: (step) => {
                if (step == 'back') {
                    if (data.step != 0) {
                        data.step--;
                    }
                } else {
                    if (data.step != 11) {
                        data.step++;
                    }
                }
            },
            fyc: (month) => {
                let step = get(data, 'step') + 1;
                let monthMerge = month >= step ? step : month;

                if (get(data, 'year') == 1) {
                    return ((data.insure * 40) / 100) * monthMerge;
                } else {
                    return ((data.insure * 40) / 100) * 12;
                }
            },
            ryc: (type, month) => {
                let step = get(data, 'step') + 1;
                let monthMerge = month >= step ? step : month;

                if (get(data, 'year') == 1) {
                    return 0;
                }

                if (get(data, 'year') == 2) {
                    if (type == 1) {
                        return ((data.insure * 20) / 100) * monthMerge;
                    } else {
                        return 0;
                    }
                }

                if (get(data, 'year') == 3) {
                    if (type == 1) {
                        return ((data.insure * 20) / 100) * 12;
                    } else {
                        return ((data.insure * 10) / 100) * monthMerge;
                    }
                }
            },
            changed: () => {
                set(data, 'step', 0);

                let ryc1 = (data.insure * 20) / 100;
                let career = 0;
                for (let i = 1; i <= 12; i++) {
                    career += ryc1 * i;
                }
                set(data, 'career', career);
            },
            total: (month) => {
                let step = get(data, 'step') + 1;
                let monthMerge = month >= step ? step : month;

                if (get(data, 'year') == 1) {
                    return ((data.insure * 40) / 100) * monthMerge;
                }
                if (get(data, 'year') == 2) {
                    let fyc = ((data.insure * 40) / 100) * 12;
                    return fyc + ((data.insure * 20) / 100) * monthMerge;
                }

                if (get(data, 'year') == 3) {
                    let fyc = ((data.insure * 40) / 100) * 12;
                    let ryc = ((data.insure * 20) / 100) * 12;
                    let cb = data.career / 12;
                    return fyc + ryc + cb + ((data.insure * 10) / 100) * monthMerge;
                }
            },
        };

        return { step, total, data, store, handle, get, find, nf };
    },
};
</script>

<style lang="scss" scoped>
.table-commitssion {
    td {
        &.head {
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
        }
        &.bottom {
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
        }
        &.first {
            border-bottom-left-radius: 8px;
        }

        &.M1 {
            background: #eb4d4b;
            color: white !important;
        }

        &.M2 {
            background: #eb994b;
            color: white !important;
        }

        &.M3 {
            background: #ebca4b;
            color: white !important;
        }
        &.M4 {
            background: #bbeb4b;
            color: white !important;
        }
        &.M5 {
            background: #4beb6b;
            color: white !important;
        }
        &.M6 {
            background: #4be1eb;
            color: white !important;
        }
        &.M7 {
            background: #4bb7eb;
            color: white !important;
        }
        &.M8 {
            background: #4b91eb;
            color: white !important;
        }
        &.M9 {
            background: #4b60eb;
            color: white !important;
        }
        &.M10 {
            background: #7b4beb;
            color: white !important;
        }
        &.M11 {
            background: #a64beb;
            color: white !important;
        }
        &.M12 {
            background: #eb4bd1;
            color: white !important;
        }

        &.color-m1 {
            color: #eb4d4b !important;
        }
        &.color-m2 {
            color: #eb994b !important;
        }
        &.color-m3 {
            color: #ebca4b !important;
        }
        &.color-m4 {
            color: #bbeb4b !important;
        }
        &.color-m5 {
            color: #4beb6b !important;
        }
        &.color-m6 {
            color: #4be1eb !important;
        }
        &.color-m7 {
            color: #4bb7eb !important;
        }
        &.color-m8 {
            color: #4b91eb !important;
        }
        &.color-m9 {
            color: #4b60eb !important;
        }
        &.color-m10 {
            color: #7b4beb !important;
        }
        &.color-m11 {
            color: #a64beb !important;
        }
        &.color-m12 {
            color: #eb4bd1 !important;
        }

        &.total {
            color: #eb4d4b;
            font-weight: bold;
            padding: 5px 8px;
        }
        padding: 3px 8px;
        vertical-align: middle;
        text-align: center;
        font-weight: bold;
    }
}
</style>
